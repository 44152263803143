.tasks {
  &_input {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    width: 90%;
    gap: 20px;
    margin: 20px auto;
  }

  &_select {
    width: 50%;
    margin: 0 auto;
  }

  &_bar {
    display: flex;
  }

  &_btn {
    border: none;
    background-color: #fff;
    transition: all ease .3s;
    &.reverse {
      transition: all ease .3s;
      transform: rotate(90deg);
      transform: rotateX(180deg);
    }
  }
}

@media (max-width: 768px) {
  .tasks {
    &_input {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 20px;
    }

    &_select {
      width: 90%;
      margin: 0 auto;
    }
  
  }
}