@import 'src/assets/style/variables.scss';

.todolist {
  &_checkbox {
    display: flex;
    justify-content: flex-end;

    label:first-child {
      margin-right: 10px;
    }
  }
}

.todo {
  &_list {
    padding: 0;
    margin-bottom: 0px;
    position: relative;
  }

  &_wrapper {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .todo {
    &_list {
      padding: 3px 10px 3px 20px;
      height: 60vh;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-bottom: 10px;
    }

    &_wrapper {
      border-top: 1px solid $bg_secondary;
      border-bottom: 1px solid $bg_secondary;
    }
  }
}