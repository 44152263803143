$color_primary: black;

$bg_primary: rgb(28, 123, 160);
$bg_secondary: rgb(221, 221, 221);

$hover_primary: rgb(202, 202, 202);

$border_primary: rgb(202, 202, 202);


$btn_bg_primary: rgb(147, 110, 75);
$btn_bg_scondary: rgba(28, 123, 160, 0.8);
$btn_bg_hover: rgb(129, 94, 62);

$box_shadow_primary: 3px 2px 3px rgba(0, 0, 0, 0.5);
$box_shadow_secondary: 3px 3px 10px 5px rgba(0, 0, 0, 0.5);

$box_shadow_hover: 5px 5px 5px rgba(0, 0, 0, 0.5);
