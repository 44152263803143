.empty {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 97vh;

  background-color: rgb(190, 190, 190);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 30px;
    width: 100%;
    background-color: rgb(190, 190, 190);
    bottom: -30px;
  }
}