.cards {
  &_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }

  &_block {
    border-radius: 5px;
    padding: 50px 20px 20px 20px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.3);
    transition: all ease .3s;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    position: relative;

    &:hover {
      transform: translateY(-10px);
      transition: all ease .3s;
    }

    &::before {
      content: '';
      position: absolute;
      top: 25px;
      right: calc(50% / 2);
      width: 50%;

      border-bottom: 1px solid #ff4040;
    }
  }
  
}


@media (max-width: 1500px){

}

@media (max-width: 1200px){
  
}

@media (max-width: 992px){
  .cards {
    &_wrapper {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
}

@media (max-width: 768px){

}

@media (max-width: 576px){
  .cards {
    &_wrapper {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}
