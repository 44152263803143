@import 'src/assets/style/variables.scss';

.footer {
  padding: 10px;
  background-color: $bg_primary;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


@media (max-width: 768px){
  .footer {
    &_logo {}

    &_rights {
      display: none;
    }
  }
}