@import 'src/assets/style/variables.scss';

.forecast {
  background: linear-gradient(138deg, rgb(152, 152, 221) 0%, rgb(55, 55, 225) 50%, rgba(0,212,255,1) 100%);
  border-radius: 5px;
  padding: 10px;
  color: white;

  &-body {
    position: relative;
    width: 100%;
    height: 300px;
  }

  &-item {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: space-around;

    &.select {
      opacity: 1;
    }
  }

  &-section {
    padding: 5px;
    margin: 0 5px;
  }

  &-bottom {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-btn {
    background-color: rgba(128, 128, 128, 0.5);
    padding: 30px 10px 20px 10px;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    &.select {
      background-color: transparent;
      border-top: 1px solid transparent;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
      color: white;
      font-weight: bold;
    }
  }
}

@media (max-width: 1000px) {
  .forecast {
    &-body { }

    &-bottom {
      height: 100px;
    }

    &-btn {
      padding: 5px;
      flex-direction: column;
    }
  }
}

@media (max-width: 500px) {
  .forecast {
    font-size: 12px;
    padding: 3px;

    &-body {
      height: 200px;
    }

    &-btn {
      padding: 3px;
    }
  }
}