.nav {
  color: #fff;
  position: relative;

  &_body {
    background-color: rgba(43,45,49,0.95);
    position: relative;
    z-index: 5000;
  }

  &_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_mobile {
    img {
      max-height: 30px;
      max-width: 30px;
      cursor: pointer;
      margin-right: 20px;
    }
    display: none;
  }

  &_list {
    &_list {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      padding: 0;
    }

    a {
      text-decoration: none;
      color: #fff;
    }
  }

  &_item {
    cursor: pointer;
  }

  & img {
    width: 80px;
  }

  &_menu {
    position: absolute;
    top: -500px;
    right: 0;
    width: 100%;
    background-color: #37393f;
    z-index: 10;
    transition: all ease-in-out .5s;
    padding-top: 50px;

    & .nav_list {
      flex-direction: column;
      margin: 20px 0;
    }

    & p {
      text-align: center;
    }
    
    &.active {
      top: 50px;
      transition: all ease .5s;
    }
  }

  &_tel {
    color: #fff;
    text-decoration: none;
  }
}


@media (max-width: 1500px) {
  .nav {
  }
}

@media (max-width: 1200px){
  .nav {
    &_body {
      background-color: rgba(43,45,49,1);
    }
  }
  
}

@media (max-width: 992px){
  .nav {
    &_main {
      display: none;
    }

    &_contacts {
      display: none;
    }

    &_mobile {
      display: block;
    }
  }

}

@media (min-width: 768px){
  .nav {
  }
}

@media (min-width: 576px){
  .nav {
  }
}
