@import 'src/assets/style/variables.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $bg_primary;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $bg_primary;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  
    &.round {
      border-radius: 34px;
  
      &:before {
        border-radius: 50%;
      }
    }
  }
}


@media (max-width: 768px) {
  .switch {
    width: 50px;
    height: 24px;

    input {
      &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
  
    .slider {
      &:before {
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
      }

      &.round {
        border-radius: 24px;

        &:before {
          border-radius: 50%;
        }
      }
    }
  }
  
}