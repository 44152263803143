.landing {
  .about {
    &_body{
      min-height: 300px;
    }
  }
}

.video {
  &_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
  }

  &_body {
    margin: 20px;
    width: 100%;
    max-width: 800px;
    height: 50%;
    background-color: transparent;
  }

  &_img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    height: 500px;
    border-radius: 10px;

    width: 100%;
    max-width: 800px;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  &_btn {
    position: absolute;
    cursor: pointer;
  }

}

@media (max-width: 768px){
  .video {
    &_body {
      margin: 10px;
      max-width: 700px;
      height: 300px;
    }
  }
  
}

@media (max-width: 576px){
  .video {
    &_body {
      margin: 10px;
      max-width: 300px;
      height: 150px;
    }

    &_img {
      border-radius: 10px;
      max-width: 800px;
      height: 250px;
    }
  }
}