@import 'src/assets/style/variables.scss';

.todoitem {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid $hover_primary;
  border-radius: 3px;
  margin-bottom: 3px;

  &.completed {
    background-color: $bg_secondary;
  }

  &_checkbox {
    margin-right: 10px;
    cursor: pointer;
    
    &:checked ~ .todoitem_text {
      text-decoration: line-through;
    } 
  }

  &_text {
    margin-right: 10px;

    &::first-letter{
      text-transform: uppercase;
    }
  }

  &_buttons {
    margin-left: auto;
    display: flex;
    flex-wrap: nowrap;
  }

  &_btn {
    cursor: pointer;
    background-color: transparent;
    border: none;

    &.hover {
      cursor: grab;
    }
  }
}

@media (max-width: 600px) {
  .todoitem {
    &_btn {
      &.hover {
        display: none;
      }
    }
  }
}