@import 'src/assets/style/variables.scss';

.button {
  padding: 0;
  border: none;
  border-radius: 5px;
  overflow: hidden;

  box-shadow: $box_shadow_primary;
  transition: all ease .3s;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
  
    max-width: 30px;
    max-height: 30px;
  }

  &_text {
    padding: 10px;
    background: $btn_bg_scondary;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_name::first-letter {
    text-transform: capitalize;
  }

  &:hover {
    box-shadow: $box_shadow_hover;
  }
}

@media (max-width: 768px){
  .button {
    &_logo {
      display: flex;
      justify-content: center;
      align-items: center;
      
      max-width: 20px;
      max-height: 20px;
    }

    &:hover {
      box-shadow: $box_shadow_primary;
    }
  }
}