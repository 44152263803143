@import 'src/assets/style/variables.scss';

.context-menu {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: $box_shadow_secondary;
  display: none;
  overflow: hidden;

  &_list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  &_item {
    padding: 15px;
    cursor: pointer;

    &:hover {
      background-color: grey;
      transition: all ease .3s;
    }
  }
}