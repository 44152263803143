.delivery {
  margin-bottom: 20px;
  
  &_wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }

  &_block {
    display: grid;
    grid-template-columns: 1fr 2fr;
    background-color: #f5f7fb;
    border-radius: 10px;
    overflow: hidden;
  }

}

.block {
  &_img {
    width: 100%;
    height: 200px;
    background-size: cover;
  }

  &_content {
    padding: 10px;

    & h3 span {
      font-size: 60px;
      margin-right: 5px;
      position: relative;
      color: #ff4040;
      &::after {
        content: '';
        border-bottom: 1px solid #ff4040;
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
      }
    }

    & p {
      color: #7d7e82;
    }
  }
}


@media (max-width: 1500px){

}

@media (max-width: 1200px){
  .delivery {
    &_block {
      display: flex;
      flex-direction: column;
    }
  }  
}

@media (max-width: 992px){

}

@media (max-width: 768px){
  .delivery {
    &_wrapper{
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 576px){

}
