.landing_footer {
  background-color: rgba(43,45,49,1);
  color: #fff;

  .footer {
    &_body {
      padding: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      gap: 30px;
      flex-wrap: wrap;
    
    }

    &_widget {
      max-width: 300px;
      position: relative;
      padding-left: 50px;
      
      & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
      }
    }

    &_bottom {
      border-top: 1px solid rgba(255,255,255,0.25);

      & .wrapper {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        img {
          width: 80px;
          height: 100%;
        }
      }
    }
  }

  .widget {
    &_title {
      margin-bottom: 10px;
      color: #7d7e82;
    }
  }

}

@media (max-width: 1500px){

}

@media (max-width: 1200px){
  
}

@media (max-width: 992px){

}

@media (max-width: 768px){
  .landing_footer {
    .footer {
      &_bottom {
        & .wrapper {
          padding: 10px;
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .landing_footer {
    .footer {
      &_body {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
}
