@import 'src/assets/style/variables.scss';

.task-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: $bg_secondary;
  border-radius: 5px;

  &_title {
    text-align: center;
  }

  &_textarea {
    border: none;
    padding: 5px;
    background-color: transparent;
    border-bottom: 1px solid $border_primary;
    font-family: 'Roboto', sans-serif;
    max-width: 600px;
    max-height: 600px;
  }

  &_datepicker {
    border: none;
    background-color: transparent;

    border-bottom: 1px solid $border_primary;
    cursor: pointer;
  }

  &_buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }
}