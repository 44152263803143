@import 'src/assets/style/variables.scss';

.nav {
  &_list {
    list-style: none;
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
  }
  
  &_link {
    position: relative;
    text-decoration: none;
    color: black;
    padding: 5px;
    border-radius: 10px;
    width: auto;
  }

  &_underline {
    position: absolute;
    bottom: -5px;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #fff;
  }
}

@media (max-width: 768px){
  .nav {
    &_list {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
