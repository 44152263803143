@import 'src/assets/style/variables.scss';

.header {
  background-color: $bg_primary;
  padding: 5px;

  &_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 35px;
  }

  &_nav {
    padding-bottom: 10px;
  }

  &_mobile {
    display: none;
  }
}

@media (max-width: 768px){
  .header {
  

    &_logo,
    &_nav {
      display: none;
    }

    &_mobile {
      display: block;
      z-index: 100;
      transition: all ease .3s;
    }
  }}
