html {
  scroll-behavior: smooth;
}

.red {
  color: #ff4040;
}

.landing {
  section {
    margin-bottom: 20px;
  }
  section:nth-child(odd) {
    background-color: #f5f7fb;
  }

  .title {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    line-height: 1em;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -15px;
      right: calc((100% / 2) - 100px);
      width: 200px;
      border-bottom: 2px solid #ff4040;
    }
  }

  .btn {
    border: none;
    font-size: 20px;
    border-radius: 15px;
    padding: 20px;
    background-color: #ff4040;
    transition: background-color .25s,color .25s;
    color: white;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    
    &::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 100%;
      opacity: .3;
      top: 0;
      left: 0;
      background: linear-gradient(to right,
      rgba(255,255,255,0) 0,
      rgba(255,255,255,0.03) 1%,
      rgba(255,255,255,0.6) 30%,
      rgba(255,255,255,0.85) 50%,
      rgba(255,255,255,0.85) 70%,
      rgba(255,255,255,0.85) 71%,
      rgba(255,255,255,0) 100%);
      transform: skew(-10deg,0);
      animation: move 5s infinite;
    }

    &_wrapper {
      text-align: center;
    }
  }

}

@media (max-width: 1500px) {

}

@media (max-width: 1200px){
  .landing {
    .container {
      max-width: 900px;
    }
  }
}

@media (max-width: 992px){
  .landing {
    .container {
      max-width: 720px;
    }
  }
}

@media (max-width: 768px){
  .landing {
    .container {
      max-width: 550px;
    }
  }
}

@media (max-width: 576px){
  .landing {
    .container {
      max-width: 350px;
    }
  }

}


@keyframes move {
  0% {
    left: -150px;
  }

  100% {
    left: 350px
  }
}
