.promo {
  max-width: 100vw;
  height: 700px;

  position: relative;
  overflow: hidden;

  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../assets/img/home-top.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    animation: zoom 15s ease-in-out infinite;
    z-index: -10;
  }

  &_figure {
    position: absolute;
    top: 190px;
    left: calc(50% - 250px);
    width: 500px;
    height: 500px;
    border: 25px solid #ff4040;
    opacity: .25;
    z-index: -5;
  }

  &_container {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    H2 {
      font-size: 50px;
      font-weight: bold;
      text-align: center;
      color: #fff;
    }

  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}


@media (max-width: 1500px) {
}

@media (max-width: 1200px){
  
}

@media (max-width: 992px){
  .promo {
    &_figure {
      width: 300px;
      height: 300px;
      left: calc(50% - 150px);
    }
  }
}

@media (min-width: 768px){
}

@media (min-width: 576px){
}
